// extracted by mini-css-extract-plugin
export var col = "case-section-module--col--j4hCc grid--col-md--wfXfj";
export var colFullHeight = "case-section-module--col-full-height--cZ6V8 case-section-module--fullHeight--b3pp2";
export var container = "case-section-module--container--JB7nS";
export var contentColRight = "case-section-module--content-col-right--amEwT grid--col-md-7--gr46i";
export var contentContainer = "case-section-module--content-container--2f0U+";
export var fullHeight = "case-section-module--fullHeight--b3pp2";
export var galleryItem = "case-section-module--gallery-item---6-Oa";
export var headContainer = "case-section-module--head-container--TLbN9 grid--row--E93eT";
export var headContainerImage = "case-section-module--head-container-image--YrQ3R grid--row--E93eT";
export var headContainerSticky = "case-section-module--head-container-sticky--Lw99v grid--row--E93eT case-section-module--paddingTB--QzMZc";
export var headContainerStickyImage = "case-section-module--head-container-sticky-image--ZeT1G grid--row--E93eT";
export var headWrapper = "case-section-module--head-wrapper--fPIXW grid--col-md-5--LXKU7";
export var headWrapperImage = "case-section-module--head-wrapper-image--k8jKV grid--col-md-5--LXKU7";
export var headWrapperSticky = "case-section-module--head-wrapper-sticky--OzCXT grid--col-md-5--LXKU7";
export var headWrapperSummaryImage = "case-section-module--head-wrapper-summary-image--Ejvh6 grid--col-md-6--uS2Fp grid--col-lg-5--OFBrD";
export var imageContainer = "case-section-module--image-container--5CsRr grid--row--E93eT";
export var imageContainerSticky = "case-section-module--image-container-sticky--RJKs1 grid--row--E93eT case-section-module--paddingTB--QzMZc";
export var imageWrapper = "case-section-module--image-wrapper--y6ELw grid--col-md-7--gr46i grid--col-lg-9--KkUdn grid--col-md-9--IxEuh grid--col-lg-8--nKOwh";
export var imageWrapperPhone = "case-section-module--image-wrapper-phone--J4Eck grid--col-md-5--LXKU7 grid--push-md-1--IjwEr grid--col-lg-4--RuHdH grid--push-lg-2--Dc-6v";
export var intro = "case-section-module--intro--dRto+ type--font-futura--HOUH4 type--text-l--+NsZF";
export var introContainer = "case-section-module--intro-container--YPJ92 grid--row--E93eT";
export var introContainerImage = "case-section-module--intro-container-image--nt8iD";
export var introContainerSticky = "case-section-module--intro-container-sticky--WPMkp";
export var introRowSticky = "case-section-module--intro-row-sticky--XFzKo grid--row--E93eT case-section-module--paddingTB--QzMZc";
export var introWrapper = "case-section-module--intro-wrapper--bT0vm grid--col-md-7--gr46i";
export var introWrapperSticky = "case-section-module--intro-wrapper-sticky--f-YKm grid--col-md-7--gr46i";
export var paddingTB = "case-section-module--paddingTB--QzMZc";
export var row = "case-section-module--row--sGWi7 grid--row--E93eT case-section-module--paddingTB--QzMZc";
export var rowContent = "case-section-module--row-content--jH1wE grid--row--E93eT case-section-module--paddingTB--QzMZc";
export var rowContentFullHeight = "case-section-module--row-content-full-height--c8wD2 grid--row--E93eT case-section-module--paddingTB--QzMZc case-section-module--fullHeight--b3pp2";
export var rowIntroImage = "case-section-module--row-intro-image--Nzh15 grid--row--E93eT case-section-module--paddingTB--QzMZc";
export var rowSticky = "case-section-module--row-sticky--n7sdG grid--row--E93eT case-section-module--paddingTB--QzMZc case-section-module--fullHeight--b3pp2";
export var summary = "case-section-module--summary--yHaMF type--font-futura--HOUH4 type--text-l--+NsZF";
export var summaryWrapper = "case-section-module--summary-wrapper--CJGrR grid--col-md-7--gr46i";