import React from 'react'

const IconBehance = React.forwardRef((props, ref)=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
            <g>
                <path ref={ref} id="Behance" d="M10.23,11.4a3,3,0,0,0,.94-.69,2.73,2.73,0,0,0,.57-1.8,3.13,3.13,0,0,0-.56-1.86A3.8,3.8,0,0,0,8,5.75H2V17.94H7.57a8.75,8.75,0,0,0,1.75-.16,3.42,3.42,0,0,0,2.27-1.57,3.61,3.61,0,0,0,.55-2,3.18,3.18,0,0,0-.48-1.78A2.83,2.83,0,0,0,10.23,11.4ZM4.46,7.87H7.15a4.68,4.68,0,0,1,1.47.19,1.09,1.09,0,0,1,.66,1.13,1.14,1.14,0,0,1-.5,1.06,2.47,2.47,0,0,1-1.31.31h-3Zm4.26,7.74a3,3,0,0,1-1.26.22h-3V12.57H7.51a3.25,3.25,0,0,1,1.23.21,1.3,1.3,0,0,1,.79,1.31A1.52,1.52,0,0,1,8.72,15.61ZM20.08,7.84h-5.3V6.32h5.3Zm1.11,2.6a3.75,3.75,0,0,0-1.58-1.33,5.29,5.29,0,0,0-2.15-.42,4.46,4.46,0,0,0-3.27,1.25,4.83,4.83,0,0,0-1.26,3.59,4.34,4.34,0,0,0,1.4,3.61,5,5,0,0,0,3.22,1.11A4.51,4.51,0,0,0,21,16.94a3,3,0,0,0,.88-1.63H19.43a2,2,0,0,1-.49.62,2.07,2.07,0,0,1-1.33.41A2.39,2.39,0,0,1,16.3,16a2.14,2.14,0,0,1-1-1.9H22a12.1,12.1,0,0,0-.08-1.79A4.47,4.47,0,0,0,21.19,10.44Zm-5.79,2.1A2.43,2.43,0,0,1,16,11.15a1.9,1.9,0,0,1,1.43-.52,2.08,2.08,0,0,1,1.42.49,2,2,0,0,1,.64,1.42Z" fill="#eff4fb"/>
            </g>
        </svg>
    )
})


export default IconBehance